import React, { useCallback, useEffect, useState } from 'react'
import classNames from 'classnames'
import { Icon } from '@mondra/ui-components'
import { IClassNameProps } from '@mondra/ui-components/@types'
import { useUpdateProductCategoryFavourite } from 'api/useUpdateProductCategoryFavourite'

interface IProductCategoryFavourite extends IClassNameProps {
  dimension: string
  dimensionId: string
  iconSize?: number
  isSet: boolean
  onChange?: (id: string, isFavourite: boolean) => void
}

export function ProductCategoryFavourite({
  dimension,
  dimensionId,
  iconSize = 18,
  isSet,
  className,
  onChange,
}: IProductCategoryFavourite) {
  const { post, remove } = useUpdateProductCategoryFavourite({ dimension, dimensionId })
  const [isFavourite, setIsFavourite] = useState<boolean>(isSet)

  useEffect(() => {
    setIsFavourite(isSet)
  }, [isSet])

  const handleSetIsFavourite = useCallback(
    async (event: React.MouseEvent<SVGSVGElement>) => {
      event.stopPropagation()
      setIsFavourite(prevState => !prevState)

      try {
        if (isFavourite) {
          await remove()
        } else {
          await post()
        }

        if (onChange) onChange(dimensionId, !isFavourite)
      } catch (error) {
        setIsFavourite(prevState => !prevState)
      }
    },
    [dimensionId, isFavourite, onChange, post, remove]
  )

  return (
    <Icon
      onClick={handleSetIsFavourite}
      size={iconSize}
      className={classNames(
        'group-hover:text-white dark:hover:text-primary-400 dark:group-hover:text-primary-400',
        isFavourite
          ? 'text-primary-500 dark:text-primary-400'
          : 'text-coolGray-900 dark:text-coolGray-500',
        className
      )}
      type={isFavourite ? 'starFilled' : 'star'}
    />
  )
}
