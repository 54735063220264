import sortBy from 'lodash/fp/sortBy'
import isEmpty from 'lodash/fp/isEmpty'
import { ICompany } from 'types'
import { useAuth0 } from '@auth0/auth0-react'
import { useSWRWithToken } from './useSWRWithToken'

export function useCompanies() {
  const { isAuthenticated } = useAuth0()
  const apiUrl = isAuthenticated ? process.env.REACT_APP_IDENTITY_COMPANY_URL : null
  const { data, error, isLoading } = useSWRWithToken<ICompany[]>(apiUrl)

  const companies = !isEmpty(data) ? sortBy('name', data) : []

  return {
    companies,
    error,
    isLoading: isLoading || isEmpty(apiUrl),
  }
}
