export const enum ProductSystemEnum {
  ORGANIC = 'Organic',
  CONVENTIONAL = 'Conventional',
}

export const enum DataProviderEnum {
  OWN = 'Own',
  SUPPLIER = 'Supplier',
  CERTIFICATION = 'Certification',
  OTHER = 'OtherSource',
}

export const enum DqsEnum {
  FAIR = 'Fair',
  GOOD = 'Good',
  POOR = 'Poor',
  VERY_GOOD = 'VeryGood',
}

export interface IEmissionFactorData {
  id: string
  taxonomyCode: string
  taxonomyName: string
  name: string
  description: string
  status: string
  createdDate: string
  createdBy: string
  updatedDate: string
  updatedBy: string
  productionSystem: string
  productionSystemSpecifics: string
  isCreatedAfterFarmingChanges: boolean
  farmingChangedDate: string
  calculator: string
  calculatorVersion: string
  isCalculatedFromSingleSite: boolean
  addressLine1: string
  addressLine2: string
  city: string
  postalCode: string
  countryCode: string
  region: string
  carbon: number
  landUsage: number
  waterUsage: number
  waterScarcity: number
  waterPollution: number
  numberOfSampledFarms: number
  numberOfFarmsWithSamePractices: number
  isSamplingOverMultipleYears: boolean
  firstDataSampleYear: number
  lastDataSampleYear: number
  dataSampleCapturedYear: number
  dataSamplePublishedYear: number
  dataProviderType: string
  dataProviderName: string
  isPeerReviewed: boolean
  peerReviewerName: string
  isThirdPartyVerified: boolean
  thirdPartyVerifierName: string
  dqsCompleteness: string
  dqsReliability: string
}

// TODO: Why IEmissionFactorData also exists
export interface IAddDataForm {
  countryCode: string
  postalCode?: string | null
  addressLine1?: string
  addressLine2?: string | null
  region?: string
  calculator: string
  calculatorVersion?: string | null
  city?: string | null
  isCreatedAfterFarmingChanges: boolean
  isCalculatedFromSingleSite: boolean
  isSamplingOverMultipleYears: boolean
  farmingChangedDate?: string
  productionSystem: string
  dataProviderType: string
  dataProviderName?: string
  productionSystemSpecifics?: string | null
  carbon: number
  waterUsage?: number | null
  waterScarcity?: number | null
  waterPollution?: number | null
  landUsage?: number | null
  numberOfSampledFarms: number
  numberOfFarmsWithSamePractices: number
  firstDataSampleYear?: number
  lastDataSampleYear?: number
  dataSampleCapturedYear?: number
  dataSamplePublishedYear: number
  isPeerReviewed: boolean
  peerReviewerName?: string
  isThirdPartyVerified: boolean
  thirdPartyVerifierName?: string
  dqsCompleteness: string
  dqsReliability: string
}

export interface ISectionProps {
  isSaving: boolean
}
