import { useMemo } from 'react'
import { API_URLS } from 'constants/apiUrls'
import { useCompanyId } from 'hooks/useCompanyId'
import { resolveSwrUrl } from 'utils/resolveUrl'
import { useSWRWithToken } from './useSWRWithToken'
import { ICompanySyncResult } from './types'

export function useCompanySync() {
  const companyId = useCompanyId()
  const url = useMemo(
    () =>
      companyId
        ? resolveSwrUrl(API_URLS.Analysis.COMPANY_SYNC, {
            companyId,
          })
        : null,
    [companyId]
  )
  const { data, error, isLoading } = useSWRWithToken<ICompanySyncResult>(url, companyId)

  return {
    data,
    error,
    isLoading,
  }
}
