import { useMemo } from 'react'
import { API_URLS } from 'constants/apiUrls'
import { resolveSwrUrl } from 'utils/resolveUrl'
import { useCompanyId } from 'hooks/useCompanyId'
import { useMutate } from './useMutate'

interface ICreateChatRes {
  id: string
}
export function useCreateChat() {
  const companyId = useCompanyId()
  const url = useMemo(
    () =>
      resolveSwrUrl(API_URLS.AI_CHAT.CREATE, {
        companyId,
      }),
    [companyId]
  )

  const { post: create, isMutating } = useMutate<ICreateChatRes>(url, companyId)

  return {
    create,
    isCreating: isMutating,
  }
}
