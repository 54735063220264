import { AuthErrorTypesEnum, CompanyErrorTypesEnum } from 'types'

export const AUTH_ERRORS = {
  [AuthErrorTypesEnum.no_token]: 'Token not found',
  [AuthErrorTypesEnum.login_required]: 'Login required',
  [AuthErrorTypesEnum.consent_required]: 'Consent required',
  [AuthErrorTypesEnum.invalid_grant]: 'Invalid grant',
  [AuthErrorTypesEnum.unauthorized]: 'Unauthorized',
  [AuthErrorTypesEnum.timeout]: 'Timeout or Network error',
  [AuthErrorTypesEnum.invalid_request]: 'Invalid request',
  [AuthErrorTypesEnum.invalid_scope]: 'Invalid scope',
  [AuthErrorTypesEnum.server_error]: 'Internal server error',
  [AuthErrorTypesEnum.interaction_required]: 'Interaction required',
  [AuthErrorTypesEnum.access_denied]: 'Access denied',
  [CompanyErrorTypesEnum.NO_COMPANIES]:
    'Apologies! but it appears you do not have access to any companies. Please contact our support team for assistance in resolving this issue.',
  [CompanyErrorTypesEnum.COMPANY_NOT_FOUND]:
    'Apologies! The company you are searching for either does not exist or you do not have access to it.',
  [CompanyErrorTypesEnum.SOURCE_COMPANY_NOT_FOUND]:
    'Apologies! The retailer company you are searching for either does not exist or you do not have access to it.',
}

export const NO_TOKEN = {
  description: 'Access token not found',
  key: 'no_token',
}
