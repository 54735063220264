import React from 'react'

export const getErrorsBlock = (
  errors: { [key: string]: string[] },
  message: string,
  traceId: string
) => {
  const errorList = Object.keys(errors)
  return (
    <div className="mt-2 text-sm font-normal leading-5 text-coolGray-500">
      <div>{message}</div>
      {errorList.length > 0 && (
        <ul className="m-4 ml-4 list-disc">
          {errorList.map(e => (
            <li key={e}>{`${e}: ${errors[e][0]}`}</li>
          ))}
        </ul>
      )}
      <div className="text-xs text-coolGray-500">TraceId - {traceId}</div>
    </div>
  )
}
