import { ISettings } from 'api/types'
import { useSettings } from 'api/useSettings'
import React, { createContext, useMemo } from 'react'

interface ISettingsProps extends ISettings {
  isLoading: boolean
}

const defaultContextProps: ISettingsProps = {
  isLoading: true,
  salesStartDate: '',
}

export const SettingsContext = createContext<ISettingsProps>(defaultContextProps)
interface IProductPrimaryDetailsProviderProps {
  children: React.ReactNode
}

export default function SettingsContextProvider({ children }: IProductPrimaryDetailsProviderProps) {
  const { data, isLoading } = useSettings()

  const providerValue = useMemo(
    () => ({
      isLoading,
      salesStartDate: data ? data.salesStartDate : defaultContextProps.salesStartDate,
    }),
    [data, isLoading]
  )

  return <SettingsContext.Provider value={providerValue}>{children}</SettingsContext.Provider>
}
