import React from 'react'
import { ApolloLink, createHttpLink, from } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { API_URLS } from 'constants/apiUrls'
import { onError } from '@apollo/client/link/error'
import { MONDRA_HEADERS } from '../constants'

export const getAuthLink = (
  tokenPromise: Promise<string>,
  showError: (params: { description: string; label: string; children: React.ReactNode }) => void
): ApolloLink => {
  const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_API_URL}${API_URLS.GRAPHQL}`,
  })

  const authLink = setContext(async (_, { headers }) => {
    const token = await tokenPromise
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
        ...MONDRA_HEADERS,
      },
    }
  })

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      if (showError) {
        showError({
          children: (
            <div className="mt-2 text-sm font-normal leading-5 text-coolGray-500">
              <div>There has been an error with GraphQL request.</div>
              {graphQLErrors && graphQLErrors.length > 0 && (
                <ul className="m-4 ml-4 list-disc">
                  {graphQLErrors.map(({ name, message, path }) => (
                    <li key={name}>{`${message} (path: ${path})`}</li>
                  ))}
                </ul>
              )}
            </div>
          ),
          description: '',
          label: 'GraphQL error:',
        })
      } else {
        graphQLErrors.forEach(({ message, locations, path }) =>
          // eslint-disable-next-line no-console
          console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
        )
      }
    } else if (networkError) {
      showError({
        children: (
          <div className="mt-2 text-sm font-normal leading-5 text-coolGray-500">
            <div>There has been an error with GraphQL request.</div>
            <div>{networkError.message}</div>
          </div>
        ),
        description: '',
        label: 'GraphQL error:',
      })
    }
  })

  return from([errorLink, authLink, httpLink])
}
