import { useToastNotification } from '@mondra/ui-components'
import { useCallback } from 'react'
import useSWRMutation from 'swr/mutation'
import { useToken } from 'hooks/useToken'
import { MutateError, TMutateError } from 'api/types'
import {
  DEFAULT_SERVER_ERROR_LABEL,
  DEFAULT_SERVER_ERROR_MESSAGE,
  CUSTOM_SUCCESS_LABEL,
  ERRORS_TO_SKIP,
  DEFAULT_ERROR_MESSAGES,
} from 'api/constants'
import { MONDRA_HEADERS, TOAST_POSITION } from 'constants/'

const enum HttpMethodsEnum {
  DELETE = 'DELETE',
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
}

interface IFetcher {
  arg: {
    payload: unknown
    token: string | undefined
    method: HttpMethodsEnum
  }
}

interface IFetcherOptions {
  headers: {
    [key: string]: string
  }
  body?: string
  method: HttpMethodsEnum
}

type TMessage = string | null

async function fetcher(url: string, { arg }: IFetcher) {
  const { payload, token, method } = arg

  const options: IFetcherOptions = {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      ...MONDRA_HEADERS,
    },
    method,
  }

  if (method !== HttpMethodsEnum.GET) {
    options.body = JSON.stringify(payload)
  }

  const res = await fetch(url, options)

  const text = await res.text()

  let response

  try {
    const data = JSON.parse(text)
    response = data
  } catch (err) {
    response = text
  }

  if (!res.ok) {
    throw new MutateError(
      { ...response, status: res.status } || {
        detail: DEFAULT_ERROR_MESSAGES[res.status] || DEFAULT_SERVER_ERROR_MESSAGE,
        message: DEFAULT_SERVER_ERROR_LABEL,
        status: res.status,
        traceId: null,
      }
    )
  }

  return response
}

const errorToastDuration = {
  duration: 5000,
}

export function useMutate<T = any>(
  url: string | null,
  companyId?: string,
  successMessage: TMessage = null,
  errorMessage: TMessage = null,
  options = {}
) {
  const { acquireToken } = useToken()
  const { showError, showSuccess } = useToastNotification(TOAST_POSITION)

  const defaultOptions = {
    onError: (error: TMutateError) => {
      const { response } = error
      const traceId = response?.traceId ? `Trace Id: ${response.traceId}` : ''
      const label = `${response.status}: ${errorMessage ?? response.message}`
      const description = `\n${response.detail}\n\n${traceId}`

      if (errorMessage || !ERRORS_TO_SKIP.includes(response.status)) {
        showError(
          {
            description,
            label,
          },
          errorToastDuration
        )
      }
    },
    onSuccess: () => {
      if (successMessage) {
        showSuccess({
          description: successMessage,
          label: CUSTOM_SUCCESS_LABEL,
        })
      }
    },
  }

  const {
    trigger: swrTrigger,
    reset,
    ...rest
  } = useSWRMutation(url, fetcher, {
    ...defaultOptions,
    ...options,
  })

  const post = useCallback(
    async (payload = {}): Promise<T> => {
      const token = await acquireToken(companyId)
      return swrTrigger<T>({ method: HttpMethodsEnum.POST, payload, token })
    },
    [acquireToken, swrTrigger, companyId]
  )

  const put = useCallback(
    async (payload = {}): Promise<T> => {
      const token = await acquireToken(companyId)
      return swrTrigger<T>({ method: HttpMethodsEnum.PUT, payload, token })
    },
    [acquireToken, swrTrigger, companyId]
  )

  const remove = useCallback(
    async (payload = {}): Promise<T> => {
      const token = await acquireToken(companyId)
      return swrTrigger<T>({ method: HttpMethodsEnum.DELETE, payload, token })
    },
    [acquireToken, swrTrigger, companyId]
  )

  const get = useCallback(
    async (payload = {}): Promise<T> => {
      const token = await acquireToken(companyId)
      return swrTrigger<T>({ method: HttpMethodsEnum.GET, payload, token })
    },
    [acquireToken, swrTrigger, companyId]
  )

  return {
    get,
    post,
    put,
    remove,
    reset,
    ...rest,
  }
}
