import { STORAGE_KEYS } from 'constants/storageKeys'
import { IRecentlyViewedItem } from '../types'
import { useRecentItems } from './useRecentItems'

export function useRecentlyViewed() {
  return useRecentItems<IRecentlyViewedItem>({
    maxItemCount: 20,
    storageKey: STORAGE_KEYS.RECENTLY_VIEWED,
  })
}
