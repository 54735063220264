import React, { createContext, useCallback, useMemo, useState } from 'react'
import { ISupplierFiltersItem } from 'types'
import { formatApiDate } from 'utils'

export interface IDateRange {
  from: Date
  to: Date
}

interface ISupplierFiltersContextProps {
  supplierFilters: ISupplierFiltersItem[]
  supplierIdsQueryParams: string[][]
  supplierIds: string[]
  dateRangeQueryParams: string[][]
  dateRange: IDateRange | null
  applyFilters: (filters: any[]) => void
  resetFilters: () => void
  applyDateRange: (range: IDateRange | null) => void
}

const defaultContextProps = {
  applyDateRange: () => {},
  applyFilters: () => {},
  dateRange: null,
  dateRangeQueryParams: [],
  resetFilters: () => {},
  supplierFilters: [],
  supplierIds: [],
  supplierIdsQueryParams: [],
}

export const SupplierFiltersContext =
  createContext<ISupplierFiltersContextProps>(defaultContextProps)
interface ISupplierFiltersContextProviderProps {
  children: React.ReactNode
}

export default function SupplierFiltersContextProvider({
  children,
}: ISupplierFiltersContextProviderProps) {
  const [filters, setFilters] = useState<ISupplierFiltersItem[]>([])
  const [dateRange, setDateRange] = useState<IDateRange | null>(null)

  const resetFilters = useCallback(() => {
    setFilters([])
    setDateRange(null)
  }, [])

  const providerValue = useMemo(
    () => ({
      applyDateRange: setDateRange,
      applyFilters: setFilters,
      dateRange,
      dateRangeQueryParams: dateRange
        ? [
            ['startDate', formatApiDate(dateRange.from)],
            ['endDate', formatApiDate(dateRange.to)],
          ]
        : [],
      resetFilters,
      supplierFilters: filters,
      supplierIds: filters.map(filter => filter.id),
      supplierIdsQueryParams: filters.map(filter => ['supplierIds', filter.id]),
    }),
    [dateRange, filters, resetFilters]
  )

  return (
    <SupplierFiltersContext.Provider value={providerValue}>
      {children}
    </SupplierFiltersContext.Provider>
  )
}
