import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Auth0Provider } from '@auth0/auth0-react'

interface IAuth0ProviderWithHistoryProps {
  children: React.ReactNode
}

export function Auth0ProviderWithHistory({ children }: IAuth0ProviderWithHistoryProps) {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN
  const clientId = process.env.REACT_APP_AUTH0_CLIENTID
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE

  const navigate = useNavigate()

  const onRedirectCallback = appState => {
    navigate(`${appState?.returnTo || window.location.pathname}${appState?.appQueryParams || ''}`)
  }
  return (
    <Auth0Provider
      domain={domain || ''}
      clientId={clientId || ''}
      audience={audience}
      useRefreshTokens
      redirectUri={`${window.location.origin}/`}
      onRedirectCallback={onRedirectCallback}
      scope="user profile email"
    >
      {children}
    </Auth0Provider>
  )
}
