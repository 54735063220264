import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Icon } from '@mondra/ui-components'
import { useCompanySync } from 'api/useCompanySync'
import { CompanySyncStatusesEnum } from 'api/types'
import { SUPPORT_MAIL } from 'constants/'

interface ICompanySyncProps {
  syncStatus: string
  lastSyncDate: string
  isLoading: boolean
}

const defaultContextProps: ICompanySyncProps = {
  isLoading: true,
  lastSyncDate: '',
  syncStatus: CompanySyncStatusesEnum.NOT_AVAILABLE,
}

export const CompanySyncContext = createContext<ICompanySyncProps>(defaultContextProps)
interface ICompanySyncContextProviderProps {
  children: React.ReactNode
}

export default function CompanySyncContextProvider({ children }: ICompanySyncContextProviderProps) {
  const [showMessage, setShowMessage] = useState<boolean>(false)
  const { data, isLoading } = useCompanySync()

  const providerValue = useMemo(
    () => ({
      isLoading,
      lastSyncDate: data?.lastSyncDate || defaultContextProps.lastSyncDate,
      syncStatus: data?.syncStatus || defaultContextProps.syncStatus,
    }),
    [data, isLoading]
  )

  const messageSection = useMemo(() => {
    return (
      <p className="pl-3 text-base font-normal text-white">
        {data?.syncStatus === CompanySyncStatusesEnum.PENDING ? (
          <>We are preparing your data, please check back shortly</>
        ) : (
          <>
            Your data is not yet ready, please{' '}
            <a
              href={`mailto:${SUPPORT_MAIL}`}
              target="_blank"
              rel="noreferrer noopener"
              className="underline hover:text-white"
            >
              contact our support team
            </a>
          </>
        )}
      </p>
    )
  }, [data?.syncStatus])

  const handleClose = useCallback(() => {
    setShowMessage(false)
  }, [])

  useEffect(() => {
    if (data && data.syncStatus && !isLoading) {
      if (
        [CompanySyncStatusesEnum.PENDING, CompanySyncStatusesEnum.DELAYED].includes(
          data?.syncStatus
        )
      ) {
        setShowMessage(true)
      } else if (
        [CompanySyncStatusesEnum.NOT_AVAILABLE, CompanySyncStatusesEnum.COMPLETED].includes(
          data?.syncStatus
        )
      ) {
        setShowMessage(false)
      }
    }
  }, [data, isLoading])

  return (
    <div className="flex flex-col">
      <CompanySyncContext.Provider value={providerValue}>{children}</CompanySyncContext.Provider>
      {showMessage && (
        <div className="fixed bottom-0 z-40 h-28 w-full">
          <div className="relative flex h-full items-center justify-center bg-primary-700 p-6 font-medium text-white">
            <Button
              className="absolute right-3 top-3 z-50 text-white hover:text-primary-700"
              iconType="close"
              isOnlyIcon
              size="sm"
              variant="ghost"
              onClick={handleClose}
            />
            <Icon className="text-white" type="incompleteData" />
            {messageSection}
          </div>
        </div>
      )}
    </div>
  )
}
