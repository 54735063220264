import { STORAGE_KEYS } from 'constants/storageKeys'
import { ISearchProductResult } from 'api/types'
import { useRecentItems } from './useRecentItems'

export function useRecentSearchHistory() {
  return useRecentItems<ISearchProductResult>({
    maxItemCount: 10,
    storageKey: STORAGE_KEYS.RECENT_SEARCHES,
  })
}
