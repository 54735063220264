import React, { createContext, useMemo, RefObject, useState, useEffect } from 'react'
import { IChildrenProps } from '@mondra/ui-components/@types'
import { useScroll } from 'react-use'

const EMPTY_REF = { current: null }
const SCROLL_THRESHOLD = 50

interface IPageScrollContext {
  hideStepper: boolean
  scrollRef?: RefObject<HTMLDivElement>
  setScrollRef: React.Dispatch<React.SetStateAction<RefObject<HTMLDivElement>>>
  setHiddenAreaHeight: React.Dispatch<React.SetStateAction<number>>
}

const defaultContextProps = {
  hideStepper: false,
  scrollRef: EMPTY_REF,
  setHiddenAreaHeight: () => {},
  setScrollRef: () => {},
}

export const PageScrollContext = createContext<IPageScrollContext>(defaultContextProps)
export function PageScrollContextProvider({ children }: IChildrenProps) {
  const [scrollRef, setScrollRef] = useState<RefObject<HTMLDivElement>>(EMPTY_REF)
  const [scrollY, setScrollY] = useState<number>(0)
  const [contentHeight, setContentHeight] = useState<number>(0)

  const [hideStepper, setHideStepper] = useState(false)
  const [hiddenAreaHeight, setHiddenAreaHeight] = useState<number>(0)

  const { y: scrollRefY } = useScroll(scrollRef)

  const height = scrollRef.current?.offsetHeight || 0

  useEffect(() => {
    if (scrollRef.current?.scrollTop) {
      setScrollY(scrollRefY)
    } else {
      setScrollY(0)
    }
  }, [scrollRefY, scrollRef])

  useEffect(() => {
    if (scrollY > SCROLL_THRESHOLD && height + hiddenAreaHeight < contentHeight) {
      setHideStepper(true)
    } else if (scrollY === 0) {
      setHideStepper(false)
    }

    if (contentHeight === 0 || height === 0) {
      setHideStepper(false)
    }
  }, [contentHeight, height, hiddenAreaHeight, scrollY])

  useEffect(() => {
    let nodeToObserve: Element | HTMLDivElement | null = scrollRef.current

    if (!nodeToObserve) return
    const resizeObserver = new ResizeObserver(() => {
      setContentHeight(nodeToObserve!.scrollHeight)
    })

    if (nodeToObserve.children.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      nodeToObserve = nodeToObserve.children[0]
    }

    resizeObserver.observe(nodeToObserve)

    // eslint-disable-next-line consistent-return
    return () => {
      if (nodeToObserve) {
        resizeObserver.unobserve(nodeToObserve)
      }
    }
  }, [scrollRef])

  const value = useMemo(
    () => ({
      hideStepper,
      scrollRef,
      setHiddenAreaHeight,
      setScrollRef,
    }),
    [hideStepper, scrollRef]
  )
  return <PageScrollContext.Provider value={value}>{children}</PageScrollContext.Provider>
}
