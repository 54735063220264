import { CompanyContext } from 'contexts/CompanyContextProvider'
import { useCallback, useContext } from 'react'

export function useCompanyFeatures() {
  const { company } = useContext(CompanyContext)
  const hasFeature = useCallback(
    (featureKey: string) => {
      return !!company?.features?.includes(featureKey)
    },
    [company?.features]
  )

  return { hasFeature }
}
