import bytes from 'bytes'
import { MAX_FILE_SIZE, ACCEPTED_EF_FILE_EXTENSIONS } from 'constants/'

export const FILE_MESSAGES = {
  DUPLICATE_NAME: 'File already exists.',
  FILE_TOO_BIG: `File uploaded is too big. Maximum file size can be uploaded is ${bytes(
    MAX_FILE_SIZE
  )}.`,
  FILE_TYPE_NOT_SUPPORTED: `Please upload only the following file types ${ACCEPTED_EF_FILE_EXTENSIONS.join(
    ', '
  )}.`,
}

export const ZOHO_USER_TOKEN_ERROR = {
  AUTHENTICATION: 'Error while authenticating zoho user',
  TOKEN: 'Error while fetching the Zoho user token',
}
