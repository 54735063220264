export const getStorageObject = key => {
  try {
    const sData = localStorage.getItem(key)
    if (sData) {
      return JSON.parse(sData)
    }
    return {}
  } catch (e) {
    // eslint-disable-next-line
    console.error('Error in parsing data', e)
    return {}
  }
}

export const setStorageObject = (key, obj = {}) => {
  localStorage.setItem(key, JSON.stringify(obj))
}
