import React from 'react'
import classNames from 'classnames'

interface ISkeletonLoaderProps {
  as?: React.ElementType
  bgColor?: string
  children?: React.ReactNode | React.ReactChildren
  className?: string
}

export function SkeletonLoader({
  as: Component = 'div',
  bgColor = 'bg-slate-200 dark:bg-coolGray-800',
  children,
  className,
}: ISkeletonLoaderProps) {
  return (
    <Component className={classNames('animate-pulse rounded-sm', bgColor, className)}>
      {children}
    </Component>
  )
}
