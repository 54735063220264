import classNames from 'classnames'
import React from 'react'

interface ICopilotLogoProps extends React.ComponentPropsWithoutRef<'svg'> {
  isAnimated?: boolean
}

export function CopilotLogo({ isAnimated, ...rest }: ICopilotLogoProps) {
  return (
    <svg viewBox="0 0 36 35" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M3.13716 17.5269C3.13716 9.31843 9.79146 2.66414 17.9999 2.66414C26.2084 2.66414 32.8627 9.31843 32.8627 17.5269V32.3897H17.9999C9.79146 32.3897 3.13716 25.7354 3.13716 17.5269Z"
        fill="#1E1E1E"
        stroke="#3AACA3"
        strokeWidth="5.22062"
      />
      <circle
        cx="10.98486"
        cy="17.8708"
        r="2.31824"
        className={classNames('fill-white', isAnimated && 'animate-bounce')}
      />
      <circle
        cx="17.7123"
        cy="17.8708"
        r="2.31824"
        className={classNames('fill-white', isAnimated && 'animate-bounce animation-delay-200')}
      />
      <circle
        cx="24.4398"
        cy="17.8708"
        r="2.31824"
        className={classNames('fill-white', isAnimated && 'animate-bounce animation-delay-500')}
      />
    </svg>
  )
}
