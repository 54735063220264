import React, { useLayoutEffect } from 'react'
import { Button, Tooltip, useToastNotification } from '@mondra/ui-components'
import { useZohoAsapUserToken } from 'api/useZohoAsapUserToken'
import { TOAST_POSITION } from 'constants/'
import { ZOHO_USER_TOKEN_ERROR } from 'constants/errorMessages'

interface IUserInfo {
  jwtToken: string
}

declare global {
  interface Window {
    ZohoHCAsap: {
      Action: (action: string) => void
      Login: (userInfo: IUserInfo) => void
      Logout: () => void
    }
    ZohoHCAsapSettings: {}
  }
}

window.ZohoHCAsapSettings = {
  hideLauncherIcon: true,
}

export function HelpIcon() {
  const { fetchZohoUserToken } = useZohoAsapUserToken()
  const { showError } = useToastNotification(TOAST_POSITION)

  useLayoutEffect(() => {
    const zohoScript = document.querySelector('[aria-label="zoho-portal"]')

    if (!zohoScript) {
      const d = document
      const s = d.createElement('script')
      s.type = 'text/javascript'
      s.defer = true
      s.ariaLabel = 'zoho-portal'
      s.src = process.env.REACT_APP_ZOHO_CHAT_URL as string
      d.getElementsByTagName('head')[0].appendChild(s)
    }
  }, [])

  const handleBtnClick = async () => {
    window.ZohoHCAsap.Logout()
    const token = await fetchZohoUserToken()
    try {
      window.ZohoHCAsap.Login({ jwtToken: token })
    } catch (error) {
      showError({
        label: ZOHO_USER_TOKEN_ERROR.AUTHENTICATION,
      })
    }
    if (window.ZohoHCAsap && window.ZohoHCAsap.Action) {
      window.ZohoHCAsap.Action('open')
    }
  }

  return (
    <Tooltip interactive content="How can we help?">
      <Button
        iconType="statusHelp"
        isOnlyIcon
        size="lg"
        variant="white"
        onClick={handleBtnClick}
        className="max-h-14 w-14 p-4.5"
      />
    </Tooltip>
  )
}
