import React, { useCallback } from 'react'
import { Icon } from '@mondra/ui-components'
import classNames from 'classnames'
import { CopilotFullLogo } from './CopilotFullLogo'

interface IMessageButtonProps {
  className: string
  icon: string
  onClick: (event) => void
  message: string
}

function MessageButton({ className, onClick, message, icon }: IMessageButtonProps) {
  return (
    <button
      type="button"
      onClick={onClick}
      className={classNames(
        'group flex h-[168px] w-[168px] flex-col items-center justify-center gap-2 rounded-lg bg-white/10 p-4 font-normal text-white delay-75 hover:-translate-y-1 focus:outline-none motion-safe:transition-all',
        className
      )}
    >
      <Icon size={35} type={icon} className="flex-shrink-0 text-current" />
      <div className="text-sm font-normal leading-5">{message}</div>
    </button>
  )
}

interface INoMessagesHelp {
  setMessage: (message: string) => void
}

export function NoMessagesHelp({ setMessage }: INoMessagesHelp) {
  const handleSetMessage = useCallback(
    event => {
      setMessage(event.currentTarget.textContent)
    },
    [setMessage]
  )
  return (
    <div className="flex h-full flex-col items-center justify-center">
      <div className="flex items-center justify-center">
        <CopilotFullLogo className="w-56" />
      </div>
      <div className="mt-3 text-center">
        <div className="font-semibold leading-6">Welcome to Sherpa - your carbon copilot</div>
        <div className="mt-1 w-[260px] text-sm leading-5">
          Sherpa is your personal AI assistant, here to help you navigate your carbon data and
          provide valuable insight here within Mondra. Let’s get started with some simple prompts:
        </div>
      </div>

      <div className="mt-14 grid grid-cols-4 gap-2 px-4">
        <MessageButton
          icon="product"
          message="What are my most environmentally friendly products?"
          className="col-span-2"
          onClick={handleSetMessage}
        />
        <MessageButton
          icon="category"
          message="Which category has the most emissions?"
          className="col-span-2"
          onClick={handleSetMessage}
        />
        <MessageButton
          icon="ingredients"
          message="Which ingredient is generating the most emissions?"
          className="col-span-2"
          onClick={handleSetMessage}
        />
        <MessageButton
          icon="dataEnrichmentAdd"
          message="What is the % of husks per category?"
          className="col-span-2"
          onClick={handleSetMessage}
        />
      </div>
    </div>
  )
}
