import React, { useCallback, useContext, useState } from 'react'
import { ISourceCompany } from 'types'
import { CompanyContext } from 'contexts/CompanyContextProvider'
import { ListSelectDialog } from 'components/ListSelectDialog'

export function RetailerSelectDialog() {
  const { sourceCompanies, sourceCompany, setSourceCompanyId } = useContext(CompanyContext)

  const [isOpen, setIsOpen] = useState(false)

  const handleTriggerButton = useCallback(() => {
    setIsOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  const handleSelect = useCallback(
    (company: ISourceCompany) => {
      handleClose()
      setSourceCompanyId(company.id)
    },
    [handleClose, setSourceCompanyId]
  )

  if (sourceCompanies.length === 0) {
    return null
  }

  return (
    <ListSelectDialog
      items={sourceCompanies}
      selected={sourceCompany}
      open={isOpen}
      showAvatarOnTrigger
      onClose={handleClose}
      onOpen={handleTriggerButton}
      onSelect={handleSelect}
      title="Which retailer would you like to see data for?"
    />
  )
}
