const envVariables = process.env

interface ITooltipDetails {
  CONTENT: string
  LINK?: string
}

export const HELP_URLS = {
  ANALYSIS: {
    TUTORIAL: 'https://support.mondra.com/portal/en/kb/saas/analysis',
  },
  BETA_RELEASE:
    'https://support.mondra.com/portal/en/kb/articles/beta-release-features-and-functionality',
  GETTING_STARTED: {
    VIDEO: envVariables.REACT_APP_GETTING_STARTED_VIDEO,
  },
  SCENARIO: {
    TUTORIAL: 'https://support.mondra.com/portal/en/kb/saas/scenarios',
  },
}

export const INFO_TOOLTIPS: Record<string, ITooltipDetails> = {
  CARBON_INTENSITY_SCORE: {
    CONTENT:
      'Use the RAG rating to evaluate the carbon impact of your product or ingredient and highlight where changes may need to be made',
    LINK: 'https://support.mondra.com/portal/en/kb/articles/carbon-intensity-scoring',
  },
  COMPOSITION_CARBON_VOLUME: {
    CONTENT: `Ingredients are displayed by recipe composition and their carbon impacts. Volume doesn't necessarily equate to the highest impact.`,
    LINK: 'https://support.mondra.com/portal/en/kb/articles/composition-to-carbon',
  },
  CONTRIBUTION_TREE_MASS: {
    CONTENT:
      'Displays the mass of an ingredient required to make the next ingredient,  and the carbon impact for each ingredient in grams.',
    LINK: 'https://support.mondra.com/portal/en/kb/articles/using-analysis-suite-product-view#Impact_Stages_chart',
  },
  CONTRIBUTION_TREE_PERCENTAGE: {
    CONTENT:
      'Displays per ingredient percentage within the whole product and the carbon impact percentage for each ingredient.',
  },
  DATA_QUALITY_SCORE: {
    CONTENT:
      'The DQS represents the degree of representativeness for the data displayed. 1(Very Good) to 5 (Poor)',
    LINK: 'https://support.mondra.com/portal/en/kb/articles/understanding-the-data-quality-score',
  },
  EMISSIONS_OVER_TIME: {
    CONTENT:
      'Displays the sales and carbon volume for the past, with predicted carbon emissions for the future based on the year sales volume.',
    LINK: 'https://support.mondra.com/portal/en/kb/articles/emissions-over-time-explained',
  },
  EMISSIONS_VARIATION: {
    CONTENT:
      'View carbon variance between ingredient groups within a category. Identify areas for improvement by comparing box and whisker charts. Click on a box to view percentile range and view outliers.',
    LINK: 'https://support.mondra.com/portal/en/kb/articles/emission-variation-explained',
  },
  EMISSIONS_VARIATION_HISTOGRAM: {
    CONTENT:
      'At base category the Histogram chart shows products across the carbon range. Click on the chart to view products.',
    LINK: 'https://support.mondra.com/portal/en/kb/articles/histogram-view-explained',
  },
  EMISSIONS_VARIATION_IMPACTS: {
    CONTENT:
      'Chart shows carbon variance within a category or product group. Identify areas for improvement by comparing box and whisker charts. Click on a box to view percentile range and view outliers.',
    LINK: 'https://support.mondra.com/portal/en/kb/articles/emission-variation-explained#Emissions_Variation_Chart',
  },
  IMPACT_STAGES: {
    CONTENT:
      'The colour coded bar represents out of 100% the impact for your category or product at each stage',
    LINK: 'https://support.mondra.com/portal/en/kb/articles/impact-stages-chart',
  },
  INGREDIENT_LIST: {
    CONTENT: 'Understand the impact of ingredients grouped by taxonomy',
    LINK: 'https://support.mondra.com/portal/en/kb/articles/using-analysis-suite-product-view#Ingredientstab',
  },
  INGREDIENTS_TABLE: {
    CONTENT:
      'The interactive chart explores which ingredient nodes are driving carbon impact by stage, supporting identification of action points',
    LINK: 'https://support.mondra.com/portal/en/kb/articles/contribution-tree',
  },
  PRODUCT_SUPPLY_CHAIN: {
    CONTENT:
      'View your product supply chain through ingredient nodes. From the product (left) down to farm level data (right).',
    LINK: 'https://support.mondra.com/portal/en/kb/articles/using-analysis-suite-product-view#Supply_Chain',
  },
  SCENARIOS_CREATED: {
    CONTENT: `Below is an overview for the number of scenarios created by your company, and the number of categories and products affected within your full assortment from created changes`,
  },
  SOURCE_COMPANIES: {
    CONTENT:
      'Switch views for each retailer you work with, analyse their assortments and create retailer specific scenarios.',
  },
  SUPPLIER_EMISSIONS_VARIATION: {
    CONTENT:
      'View carbon variance between suppliers within a category. Identify areas for improvement by comparing box and whisker charts.',
    LINK: 'https://support.mondra.com/portal/en/kb/articles/emission-variation-explained',
  },
  SUPPLIER_IMPACT_VOLUME: {
    CONTENT:
      'A display of carbon impact per supplier, for the products or ingredients within the category the provide',
    LINK: 'https://support.mondra.com/portal/en/kb/articles/using-analysis-category-view#Supplier_Impact_Volume',
  },
}

export const STATS_CONTENT = {
  MONDRA_UPDATES: {
    DESC: 'Always improving and updating to give you the best possible answers and service.',
    LINK: 'https://support.mondra.com/portal/en/kb/what-s-new-in-mondra',
    UPDATES: ['Welcome to Mondra', 'Latest release notes'],
  },
  SCENARIOS: {
    DESC: 'Bringing eco-performance into the product development process, re-compose and re-source to improve.',
  },
  SUPPLIERS: {
    DESC: 'Brand owners, analyse your supplier’s eco-performance, invite them to collaborate on Net Zero targets.',
    LEARN_MORE:
      'https://support.mondra.com/portal/en/kb/articles/inviting-suppliers-to-the-mondra-platform-28-9-2023',
  },
}

export const GET_SUPPORT = 'https://support.mondra.com/portal/en/newticket'

export const ACCORDIAN_CONTENT = {
  BIODIVERSITY: {
    DESC: 'Measuring the impact that is made to the living environment.',
    LEARN_MORE:
      'https://support.mondra.com/portal/en/kb/articles/4-environmental-metrics-what-do-they-mean#BIODIVERSITY',
  },
  CARBON_IMPACT: {
    DESC: 'A measure of Greenhouse Gases (GHGs) that are emitted when creating the product.',
    LEARN_MORE:
      'https://support.mondra.com/portal/en/kb/articles/4-environmental-metrics-what-do-they-mean#CARBON',
  },
  WATER_POLLUTION: {
    DESC: 'Measuring the amount of pollution added to nearby water sources.',
    LEARN_MORE:
      'https://support.mondra.com/portal/en/kb/articles/4-environmental-metrics-what-do-they-mean#WATER_POLLUTION_EUTROPHICATION',
  },
  WATER_USUAGE: {
    DESC: 'Measuring the amount of water used and where it is drawn from.',
    LEARN_MORE:
      'https://support.mondra.com/portal/en/kb/articles/4-environmental-metrics-what-do-they-mean#WATER_USAGE',
  },
}

export const HUSKS = {
  URL: 'https://support.mondra.com/portal/en/kb/articles/what-are-husks',
}

export const EF_HELP_URLS = {
  CALCULATOR:
    'https://support.mondra.com/portal/en/kb/articles/create-an-emissions-factor-record-20-3-2024#Learn_about_farm_carbon_calculators',
  CARBON_ACCOUNTING:
    'https://support.mondra.com/portal/en/kb/articles/create-an-emissions-factor-record-20-3-2024#Entering_your_Carbon_amount',
  DQS: 'https://support.mondra.com/portal/en/kb/articles/create-an-emissions-factor-record-20-3-2024#Data_quality_scores_and_WRAP_DQF',
  OTHER_IMPACTS:
    'https://support.mondra.com/portal/en/kb/articles/create-an-emissions-factor-record-20-3-2024#Other_impacts',
  PRODUCTION_SYSTEM:
    'https://support.mondra.com/portal/en/kb/articles/create-an-emissions-factor-record-20-3-2024#How_to_define_your_production_system',
  RECORD_SOURCING:
    'https://support.mondra.com/portal/en/kb/articles/create-an-emissions-factor-record-20-3-2024#Understanding_record_sourcing',
  SOURCE_LOCATION:
    'https://support.mondra.com/portal/en/kb/articles/create-an-emissions-factor-record-20-3-2024#Source_location_what_to_know',
}

export const GUIDE_TO_SYMBOLS = {
  URL: 'https://support.mondra.com/portal/en/kb/articles/contribution-tree#Guide_to_Symbols',
}
