import { Dimensions } from 'constants/'
import { ANALYSIS_SUB_ROUTES, ROUTE_URLS } from 'constants/routeUrls'

const PRODUCTS_TAB_PATH = `${ROUTE_URLS.ANALYSIS}/${ANALYSIS_SUB_ROUTES.PRODUCTS}`
const INGREDIENTS_TAB_PATH = `${ROUTE_URLS.ANALYSIS}/${ANALYSIS_SUB_ROUTES.INGREDIENTS}`

export function isProduct(dimension: string) {
  return dimension === Dimensions.Product
}

export function isProductCategory(dimension: string) {
  return dimension === Dimensions.ProductCategory
}

export function isProductsTab(pathname: string) {
  return pathname === PRODUCTS_TAB_PATH
}

export function isIngredientsTab(pathname: string) {
  return pathname === INGREDIENTS_TAB_PATH
}
