/* eslint-disable */
import React, { useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { IChildrenProps } from '@mondra/ui-components/@types'
import { useAuth0 } from '@auth0/auth0-react'
import { CompanyContext } from 'contexts/CompanyContextProvider'

declare global {
  interface Window {
    pendo: {
      initialize: (visitor: IPendoInitConfig) => void
      identify: (visitor: IPendoInitConfig) => void
      updateOptions: (IPageConfig) => void
      track: (eventKey: string, eventDetails: Record<string, string>) => void
    }
  }
}

interface IPageConfig {
  path: string
}

interface IPendoInitConfig {
  visitor: { id?: string; mondrauser: boolean }
  account: {
    id: string
  }
}

export function PendoTracker({ children }: IChildrenProps) {
  const { user } = useAuth0()
  const { company } = useContext(CompanyContext)
  const location = useLocation()
  const isMondraUser = user?.[process.env.REACT_APP_IS_MONDRA_USER as string]
  useEffect(() => {
    if (user?.sub && company?.id) {
      window.pendo.identify({
        visitor: { id: user?.sub, mondrauser: isMondraUser },
        account: {
          id: company?.id,
        },
      })
    }
  }, [user?.sub, company?.id, isMondraUser])

  useEffect(() => {
    window.pendo.updateOptions({ path: location.pathname })
  }, [location.pathname])

  return children
}
