import { DEFAULT_FETCH_ERROR } from './constants'
import { MONDRA_HEADERS } from '../constants'
import { getErrorsBlock } from './utils'

interface IErrorData extends ErrorConstructor {
  info: Response
  status: number
}

interface IFetchWithTokenProps {
  apiUrl: string
  companyId?: string
  showError?: (params: { description: string; label: string; children: React.ReactNode }) => void
  acquireToken: (companyId?: string) => Promise<any>
}

export const fetchWithToken = async ({
  apiUrl,
  companyId,
  acquireToken,
  showError,
}: IFetchWithTokenProps) => {
  const token = await acquireToken(companyId)
  const options = process.env.REACT_APP_ENABLE_MOCK
    ? {}
    : {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          ...MONDRA_HEADERS,
        },
      }
  const res = await fetch(apiUrl, options)

  if (!res.ok) {
    const info = await res.json()
    const { errors, message, traceId } = info

    if (showError && errors && message && traceId) {
      showError({
        children: getErrorsBlock(errors, message, traceId),
        description: '',
        label: 'Error:',
      })
    }

    // eslint-disable-next-line
    throw {
      ...(new Error(DEFAULT_FETCH_ERROR) as Partial<IErrorData>),
      info,
      status: res.status,
    }
  }

  return res.json()
}
