import { useCallback, useState } from 'react'
import { MONDRA_HEADERS } from 'constants/'
import isEmpty from 'lodash/fp/isEmpty'
import { MutateError } from 'api/types'
import {
  DEFAULT_ERROR_MESSAGES,
  DEFAULT_SERVER_ERROR_MESSAGE,
  DEFAULT_SERVER_ERROR_LABEL,
} from 'api/constants'
import { useToken } from './useToken'
import { useCompanyId } from './useCompanyId'

type PayloadType = Record<string, string[] | string | number | null | undefined>

export function useStreamingApi() {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string>('')
  const companyId = useCompanyId()
  const { acquireToken } = useToken()
  const run = useCallback(
    async (url: string, query: PayloadType, onNext) => {
      const controller = new AbortController()
      const { signal } = controller
      // 5 mins timeout
      const timeoutId = setTimeout(() => {
        controller.abort()
      }, 300000)
      try {
        setLoading(true)
        setError('')
        const token = await acquireToken(companyId)
        const response = await fetch(url, {
          body: JSON.stringify(query),
          headers: {
            Accept: 'text/event-stream',
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            ...MONDRA_HEADERS,
          },
          method: 'post',
          signal,
        })
        if (!response.ok) {
          const errorRes = await response.json()
          const errorResFinal = !isEmpty(errorRes)
            ? errorRes
            : {
                detail: DEFAULT_ERROR_MESSAGES[errorRes.status] || DEFAULT_SERVER_ERROR_MESSAGE,
                message: response.statusText || DEFAULT_SERVER_ERROR_LABEL,
                status: errorRes.status,
                traceId: null,
              }
          throw new MutateError(errorResFinal)
        }
        if (response.body) {
          const reader = response.body.getReader()
          const decoder = new TextDecoder()
          const loopRunner = true
          let chunk = ''
          while (loopRunner) {
            /* eslint-disable no-await-in-loop */
            const { value, done } = await reader.read()
            if (done) {
              break
            }
            const decodedChunk = decoder.decode(value, { stream: true })
            chunk += decodedChunk
            onNext(chunk)
          }
        }
      } catch (err: any) {
        const { response, message: errMessage } = err
        const traceId = response?.traceId ? `(Trace Id: ${response.traceId})` : ''
        let message = !isEmpty(response)
          ? `${response.message}${traceId}: ${response.detail}`
          : errMessage
        if (err.name === 'TimeoutError') {
          message = 'Timeout: It took more than 5 seconds to get the result!'
        } else if (err.name === 'AbortError') {
          message = 'Fetch aborted by user action (browser stop button, closing tab, etc.'
        } else if (err.name === 'TypeError') {
          console.error('AbortSignal.timeout() method is not supported')
        }
        onNext(message, true)
        setError(message)
      } finally {
        setLoading(false)
        clearTimeout(timeoutId)
      }
    },
    [acquireToken, companyId]
  )
  return {
    error,
    loading,
    run,
  }
}
