import React, { useMemo } from 'react'
import { ApolloClient, ApolloProvider, DefaultOptions, InMemoryCache } from '@apollo/client'
import { getAuthLink } from 'utils/graphQLUtils'
import { useToastNotification } from '@mondra/ui-components'
import { TOAST_POSITION } from 'constants/'
import { useToken } from 'hooks/useToken'
import { useCompanyId } from 'hooks/useCompanyId'

interface IApolloClientProviderProps {
  children: React.ReactNode
}

const DEFAULT_OPTIONS: DefaultOptions = {
  query: {
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  },
  watchQuery: {
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  },
}

export default function ApolloClientProvider({ children }: IApolloClientProviderProps) {
  const { showError } = useToastNotification(TOAST_POSITION)
  const { acquireToken } = useToken()

  const companyId = useCompanyId()

  const client = useMemo(() => {
    return new ApolloClient({
      cache: new InMemoryCache(),
      defaultOptions: DEFAULT_OPTIONS,
      link: getAuthLink(acquireToken(companyId), showError),
    })
  }, [acquireToken, companyId, showError])

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
