import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import isEmpty from 'lodash/fp/isEmpty'
import { IValidationError } from 'api/types'
import { useEmissionFactorData } from 'api/useEmissionFactorData'
import { useValidateEmissionFactorData } from 'api/useValidateEmissionFactorData'
import { ROUTE_URLS } from 'constants/routeUrls'
import { IEmissionFactorData } from 'pages/add-data/add-data-form/types'

interface IAddDataProps {
  id: string | null
  isFormFieldError: boolean
  isLoading: boolean
  isValidating: boolean
  isValid: boolean
  data?: IEmissionFactorData
  errors: IValidationError[]
  refetchFormData: () => void
  setErrors: (errors: IValidationError[]) => void
  setIsFormFieldError: React.Dispatch<React.SetStateAction<boolean>>
  validate: () => Promise<boolean>
}

const defaultContextProps: IAddDataProps = {
  data: undefined,
  errors: [],
  id: null,
  isFormFieldError: false,
  isLoading: true,
  isValid: false,
  isValidating: false,
  refetchFormData: () => {},
  setErrors: () => {},
  setIsFormFieldError: () => false,
  validate: async () => false,
}

export const AddDataContext = createContext<IAddDataProps>(defaultContextProps)
interface IAddDataContextProviderProps {
  children: React.ReactNode
}

export default function AddDataContextProvider({ children }: IAddDataContextProviderProps) {
  const [isValid, setIsValid] = useState<boolean>(false)
  const [errors, setErrors] = useState<IValidationError[]>([])
  // TODO - Need to improve this with section wise errors and show Error indicator in the footer
  const [isFormFieldError, setIsFormFieldError] = useState<boolean>(false)

  const navigate = useNavigate()
  const [queryParams] = useSearchParams()
  const id = queryParams.get('id')
  const { data, error, isLoading, refetch } = useEmissionFactorData({ id })
  const { validate, isValidating } = useValidateEmissionFactorData({ id })

  useEffect(() => {
    if (isEmpty(id) || (!isLoading && !isEmpty(error))) {
      navigate(ROUTE_URLS.ADD_DATA)
    }
  }, [error, isLoading, id, navigate])

  const handleValidate = useCallback(async () => {
    setErrors([])
    const { errors: formErrors, isValid: isFormValid } = await validate()
    setIsValid(isFormValid)
    setErrors(formErrors)

    return isFormValid
  }, [validate])

  const providerValue = useMemo(
    () => ({
      data,
      errors,
      id,
      isFormFieldError,
      isLoading,
      isValid,
      isValidating,
      refetchFormData: refetch,
      setErrors,
      setIsFormFieldError,
      validate: handleValidate,
    }),
    [data, errors, handleValidate, id, isLoading, isValid, isValidating, refetch, isFormFieldError]
  )

  return <AddDataContext.Provider value={providerValue}>{children}</AddDataContext.Provider>
}
