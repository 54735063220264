import { useAuth0 } from '@auth0/auth0-react'
import { SAAS_APP } from 'constants/'
import { IString } from '@mondra/ui-components/@types'

interface ItriggerLoginProps {
  companyId: IString | null
  defaultUrl?: string
}
export function useLoginRedirect() {
  const { loginWithRedirect } = useAuth0()
  function triggerLogin({ companyId, defaultUrl }: ItriggerLoginProps) {
    const additionalParams = companyId
      ? {
          companyId,
          mondra_app: SAAS_APP,
          screen_hint: 'signup',
        }
      : {
          mondra_app: SAAS_APP,
        }
    loginWithRedirect({
      appState: {
        appQueryParams: window.location.search,
        returnTo: `${defaultUrl || window.location.pathname}`,
      },
      ...additionalParams,
    })
  }
  return triggerLogin
}
