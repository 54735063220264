import React, {
  SyntheticEvent,
  useCallback,
  useContext,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import classNames from 'classnames'
import {
  TriggerButton,
  Icon,
  Notification,
  Textarea,
  Slideover,
  Tooltip,
} from '@mondra/ui-components'
import { useReactToPrint } from 'react-to-print'
import { useControls } from 'hooks/useControls'
import { useCreateChat } from 'api/useCreateChat'
import { StringType } from 'types'
import { SupplierFiltersContext } from 'contexts/SupplierFilterContextProvider'
import { AnalysisUrlContext } from 'contexts/AnalysisUrlContextProvider'
import { useStreamingApi } from 'hooks/useStreamingApi'
import { API_URLS } from 'constants/apiUrls'
import { isEmpty } from 'lodash/fp'
import { useCompanyId } from 'hooks/useCompanyId'
import { useSourceCompanyId } from 'hooks/useSourceCompanyId'
import { resolveApiUrl } from 'utils/resolveUrl'
import { EMPTY_STRING } from 'constants/'
import { formatApiDate } from 'utils'
import { ChatMessages, IMessage } from './ChatMessages'
import { NewChat } from './NewChat'
import { NoMessagesHelp } from './NoMessagesHelp'
import { CopilotLogo } from './CopilotLogo'
import { DarkActionButton } from './DarkActionButton'
import { SendIcon } from './SendIcon'

const CHAT_NOT_AVAILABLE: string = 'CHAT_NOT_AVAILABLE'
const TEXTAREA_DEFAULT_HEIGHT = 40
const TEXTAREA_DEFAULT_SCROLL_HEIGHT = 44

export function CopilotChat() {
  const [wider, setWider] = useState<boolean>(false)
  const [activeChatId, setActiveChatId] = useState<StringType>('')
  const [messages, setMessages] = useState<IMessage[]>([])
  const formRef = useRef<HTMLFormElement>(null)
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const contentScrollRef = useRef<HTMLDivElement>(null)
  const { supplierIds, dateRange } = useContext(SupplierFiltersContext)
  const { dashboard } = useContext(AnalysisUrlContext)
  const companyId = useCompanyId()
  const sourceCompanyId = useSourceCompanyId()
  const { open, isOpened, close } = useControls()
  const { create, isCreating } = useCreateChat()
  const { run, loading } = useStreamingApi()
  const handlePrint = useReactToPrint({
    content: () => contentScrollRef.current,
    documentTitle: 'Mondra Sherpa Insights Chat',
    removeAfterPrint: true,
  })
  const scrollToBottom = () => {
    contentScrollRef.current?.scrollTo({
      behavior: 'smooth',
      top: contentScrollRef.current?.scrollHeight,
    })
  }

  const handleResponse = useCallback((stream, isError) => {
    if (stream) {
      setMessages(prevMessages => {
        const newMessages = [...prevMessages]
        newMessages[newMessages.length - 1].text = stream
        newMessages[newMessages.length - 1].isError = isError
        return newMessages
      })
    }
  }, [])

  useLayoutEffect(() => {
    if (messages.length > 0) {
      scrollToBottom()
    }
  }, [messages])

  const handleSendMessage = useCallback(
    (message: string) => {
      const newMessageId = crypto.randomUUID()
      if (!isEmpty(message)) {
        setMessages(prevMessages => [
          ...prevMessages,
          {
            id: newMessageId,
            text: message,
            type: 'user',
          },
          { id: `respons-${newMessageId}`, text: '', type: 'bot' },
        ])
        const url =
          !isEmpty(companyId) && !isEmpty(activeChatId) && activeChatId !== CHAT_NOT_AVAILABLE
            ? resolveApiUrl(API_URLS.AI_CHAT.MESSAGE_STREAM, {
                companyId,
                id: activeChatId as string,
              })
            : EMPTY_STRING
        if (!isEmpty(url)) {
          const payload = {
            dimension: dashboard.dimension,
            dimensionId: dashboard.id,
            endDate: dateRange ? formatApiDate(dateRange.to) : null,
            message,
            sourceCompanyId: isEmpty(sourceCompanyId) ? null : sourceCompanyId,
            startDate: dateRange ? formatApiDate(dateRange.from) : null,
            supplierIds,
          }
          run(url, payload, handleResponse)
        }
      }
    },
    [
      companyId,
      activeChatId,
      run,
      handleResponse,
      supplierIds,
      dateRange,
      sourceCompanyId,
      dashboard.dimension,
      dashboard.id,
    ]
  )

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      event.stopPropagation()
      const formData = new FormData(formRef?.current as HTMLFormElement)
      const query = formData.get('query')
      handleSendMessage(query as string)
      formRef.current?.reset()
      if (textareaRef.current) {
        textareaRef.current.style.height = `${TEXTAREA_DEFAULT_HEIGHT}px`
        textareaRef.current.focus()
      }
    },
    [handleSendMessage]
  )

  const handleDefaultSendMessage = useCallback(
    (message: string) => {
      handleSendMessage(message)
    },
    [handleSendMessage]
  )

  const handleCreate = useCallback(
    async isNew => {
      try {
        if (isNew || !activeChatId) {
          const createRes = await create()
          if (createRes.id) {
            setActiveChatId(createRes.id)
          } else {
            setActiveChatId(CHAT_NOT_AVAILABLE)
          }
        }
      } catch (err) {
        setActiveChatId(CHAT_NOT_AVAILABLE)
        console.log('Error', err)
      }
    },
    [create, activeChatId]
  )

  const handleOpen = useCallback(async () => {
    open()
    handleCreate(false)
  }, [open, handleCreate])

  const handleCreateChat = useCallback(() => {
    setMessages([])
    handleCreate(true)
  }, [handleCreate])

  const handleMessageKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault()
        handleSubmit(event as unknown as React.FormEvent<HTMLFormElement>)
      }
    },
    [handleSubmit]
  )

  const onChangeHandler = useCallback((e: SyntheticEvent) => {
    const target = e.target as HTMLTextAreaElement
    if (textareaRef.current) {
      const height =
        target.scrollHeight <= TEXTAREA_DEFAULT_SCROLL_HEIGHT || isEmpty(textareaRef.current.value)
          ? TEXTAREA_DEFAULT_HEIGHT
          : target.scrollHeight
      textareaRef.current.style.height = 'auto'
      textareaRef.current.style.height = `${height}px`
    }
  }, [])

  const handleWider = useCallback(() => {
    setWider(!wider)
  }, [wider])

  const isChatNotAvailable = activeChatId === CHAT_NOT_AVAILABLE
  const isNoMessages = !loading && messages.length === 0
  return (
    <div>
      <Tooltip content="Mondra Sherpa">
        <TriggerButton
          className="fixed bottom-8 right-8 z-50 h-9 w-9 !border-none !bg-transparent !p-0"
          onClick={handleOpen}
          disabled={isCreating}
        >
          <CopilotLogo className="h-14 w-14" />
        </TriggerButton>
      </Tooltip>
      <Slideover
        isOpen={isOpened}
        onClose={close}
        wider={classNames('w-screen', !wider && 'max-w-3xl')}
      >
        <div className="flex h-full max-h-screen flex-col overflow-hidden bg-coolGray-900 text-white">
          <div
            className={classNames(
              'sticky top-0 z-10 flex w-full items-center space-x-2 px-4 py-3',
              isNoMessages ? 'justify-end' : 'justify-between'
            )}
          >
            {!isNoMessages && (
              <div className="flex items-center space-x-2">
                <Tooltip content={wider ? 'Collapse chat' : 'Expand chat'} placement="top-start">
                  <DarkActionButton
                    iconType={wider ? 'chevronRight' : 'chevronLeft'}
                    onClick={handleWider}
                  />
                </Tooltip>
                <div className="text-lg font-medium">Mondra Sherpa</div>
                {isCreating && (
                  <div>
                    <Icon type="spinnerThird" className="animate-spin text-white" />
                  </div>
                )}
              </div>
            )}
            <div className="flex items-center space-x-2">
              {!isNoMessages && (
                <>
                  <NewChat isCreating={isCreating} onCreate={handleCreateChat} />
                  <Tooltip content="Save chat">
                    <DarkActionButton iconType="fileExport" onClick={handlePrint} />
                  </Tooltip>
                </>
              )}
              <Tooltip content="Close chat" placement="top-end">
                <DarkActionButton onClick={close} iconType="close" />
              </Tooltip>
            </div>
          </div>
          <div
            ref={contentScrollRef}
            className={classNames(
              'flex w-full flex-grow flex-col overflow-y-auto overflow-x-hidden print:bg-white print:p-4 print:!text-coolGray-900'
            )}
          >
            <div
              className={classNames(
                'flex w-full flex-grow flex-col',
                wider ? 'mx-auto max-w-6xl' : 'w-full'
              )}
            >
              {isChatNotAvailable && (
                <div className="p-4">
                  <Notification type="error">Mondra Sherpa is not available</Notification>
                </div>
              )}
              {messages.length === 0 ? (
                <NoMessagesHelp setMessage={handleDefaultSendMessage} />
              ) : (
                <ChatMessages loading={loading} messages={messages} />
              )}
            </div>
          </div>
          <div className={classNames('py-4', wider ? 'pl-24 pr-4 xl:px-4' : 'px-4')}>
            <form ref={formRef} onSubmit={handleSubmit}>
              <div className="mx-auto flex max-w-6xl items-end space-x-4 rounded-[28px] bg-white/10 p-2">
                <CopilotLogo isAnimated={loading} className="h-10 w-10" />
                <Textarea
                  ref={textareaRef}
                  disabled={isChatNotAvailable}
                  placeholder="What would you like to ask Mondra Sherpa?"
                  className="!max-h-[200px] flex-1 resize-none overflow-hidden !border-none !bg-transparent !py-3 text-base leading-5 placeholder:text-white/40 focus:!ring-0"
                  name="query"
                  onKeyDown={handleMessageKeyDown}
                  onChange={onChangeHandler}
                />
                <DarkActionButton
                  isTransparent
                  disabled={loading || isChatNotAvailable}
                  type="submit"
                >
                  <SendIcon className="mb-1 h-10 w-10 text-white" />
                </DarkActionButton>
              </div>
            </form>
          </div>
        </div>
      </Slideover>
    </div>
  )
}
