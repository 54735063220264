import { useMemo } from 'react'
import { API_URLS } from 'constants/apiUrls'
import { resolveSwrUrl } from 'utils/resolveUrl'
import { useCompanyId } from 'hooks/useCompanyId'
import { useMutate } from './useMutate'
import { useSWRWithToken } from './useSWRWithToken'
import { ISettings } from './types'

export function useSettings() {
  const companyId = useCompanyId()

  const settingsUrl = useMemo(
    () =>
      resolveSwrUrl(API_URLS.SETTINGS, {
        companyId,
      }),
    [companyId]
  )

  const { data, isLoading } = useSWRWithToken<ISettings | undefined>(settingsUrl, companyId)
  const { post: updateSettings, isMutating: isUpdating } = useMutate(settingsUrl, companyId)

  return {
    data,
    isLoading,
    isUpdating,
    updateSettings,
  }
}
