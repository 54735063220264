import { ISourceCompany } from 'types'
import { resolveApiUrl } from 'utils/resolveUrl'
import { API_URLS } from 'constants/apiUrls'
import { useSWRWithToken } from './useSWRWithToken'

export function useSourceCompanies(companyId?: string) {
  const { data, error, isLoading } = useSWRWithToken<ISourceCompany[]>(
    companyId ? resolveApiUrl(API_URLS.SOURCE_COMPANIES, { companyId }) : null,
    companyId
  )

  return {
    data: data || [],
    error,
    isLoading,
  }
}
