import { useAuth0 } from '@auth0/auth0-react'

export function useLogout() {
  const { logout } = useAuth0()
  function triggerLogout() {
    logout({ returnTo: window.location.origin })
  }

  return triggerLogout
}
