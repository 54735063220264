import { useMemo } from 'react'
import { API_URLS } from 'constants/apiUrls'
import { resolveSwrUrl } from 'utils/resolveUrl'
import { useCompanyId } from 'hooks/useCompanyId'
import { NullableStringType } from 'types'
import { useMutate } from './useMutate'
import { IEFValidation } from './types'

interface IValidateEmissionFactorDataProps {
  id: NullableStringType
}

export function useValidateEmissionFactorData({ id }: IValidateEmissionFactorDataProps) {
  const companyId = useCompanyId()
  const url = useMemo(
    () =>
      id
        ? resolveSwrUrl(API_URLS.AGDATA.VALIDATE, {
            companyId,
            id,
          })
        : null,
    [companyId, id]
  )

  const { get, isMutating } = useMutate<IEFValidation>(url, companyId)

  return {
    isValidating: isMutating,
    validate: get,
  }
}
