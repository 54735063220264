import React, { ReactNode } from 'react'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'
import { Icon } from '@mondra/ui-components'

interface ISidebarProps {
  children?: React.ReactNode
  className?: string
}
export function Sidebar({ className, children }: ISidebarProps) {
  return (
    <aside
      className={classNames(
        'relative flex h-full w-80 flex-col border-t border-coolGray-700 bg-coolGray-900 pt-4',
        className
      )}
    >
      <ul className="flex w-full flex-grow flex-col overflow-y-auto">{children}</ul>
    </aside>
  )
}

interface ISidebarItemProps extends React.ComponentPropsWithoutRef<'div'> {
  as?: React.ElementType
  disabled?: boolean
  hover?: boolean
  iconType: string
  right?: ReactNode
  title: string
  to?: string
}

Sidebar.Item = function Item({
  as: Comp = NavLink,
  className,
  children,
  disabled,
  iconType,
  right,
  title,
  to,
  ...rest
}: ISidebarItemProps) {
  const classes = ({ isActive }: { isActive: boolean }) =>
    classNames(
      'nav-item relative box-border flex items-center transition-all duration-100 ease-in w-full pl-5 py-2 border-l-4 border-transparent',
      'font-medium text-sm leading-8',
      !disabled && 'hover:text-white hover:bg-graySecondary-900 cursor-pointer',
      !disabled && isActive && 'bg-coolGray-700 text-white !border-primary-500',
      !disabled && !isActive && 'text-coolGray-200 font-medium',
      disabled && '!text-coolGray-400 cursor-not-allowed',
      className
    )

  return (
    <li>
      <Comp className={classes} to={to} {...rest} title={title}>
        <Icon type={iconType} size={20} className="text-current" />
        <div className="ml-6 w-full">{right}</div>
      </Comp>
    </li>
  )
}
