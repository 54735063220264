import { useMemo } from 'react'
import { API_URLS } from 'constants/apiUrls'
import { NullableStringType } from 'types'
import { resolveSwrUrl } from 'utils/resolveUrl'
import { useCompanyId } from 'hooks/useCompanyId'
import { IEmissionFactorData } from 'pages/add-data/add-data-form/types'
import { useSWRWithToken } from './useSWRWithToken'

interface IEmissionFactorDataProps {
  id: NullableStringType
}

export function useEmissionFactorData({ id }: IEmissionFactorDataProps) {
  const companyId = useCompanyId()
  const url = useMemo(
    () =>
      id
        ? resolveSwrUrl(API_URLS.AGDATA.DATA, {
            companyId,
            id,
          })
        : null,
    [companyId, id]
  )

  const { data, error, isLoading, mutate } = useSWRWithToken<IEmissionFactorData>(url, companyId, {
    revalidateIfStale: true,
  })

  return {
    data,
    error,
    isLoading,
    refetch: mutate,
  }
}
