import { useMemo } from 'react'
import { API_URLS } from 'constants/apiUrls'
import { useCompanyId } from 'hooks/useCompanyId'
import { resolveSwrUrl } from 'utils/resolveUrl'
import { IDashboardApiCommonProps } from 'types'
import { useMutate } from './useMutate'
import { FAVOURITE_ERROR } from './constants'

export function useUpdateProductCategoryFavourite({
  dimension,
  dimensionId,
}: IDashboardApiCommonProps) {
  const companyId = useCompanyId()
  const url = useMemo(
    () =>
      dimension && dimensionId
        ? resolveSwrUrl(API_URLS.Analysis.FAVOURITE, {
            companyId,
            dimension,
            dimensionId,
          })
        : null,
    [companyId, dimension, dimensionId]
  )
  const { post, remove, isMutating } = useMutate(url, companyId, null, FAVOURITE_ERROR)

  return {
    isLoading: isMutating,
    post,
    remove,
  }
}
