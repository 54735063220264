import React from 'react'
import { Button, Tooltip } from '@mondra/ui-components'
import { IClassNameProps } from '@mondra/ui-components/@types'
import { INFO_TOOLTIPS } from 'constants/helpContent'

interface IInfoTooltipProps extends IClassNameProps {
  tooltipKey: string
}

export function InfoTooltip({ className = '!text-coolGray-700', tooltipKey }: IInfoTooltipProps) {
  const tooltipDetails = INFO_TOOLTIPS[tooltipKey]

  const navigateTo = () => {
    window.open(tooltipDetails?.LINK, 'noopener')
  }

  if (!tooltipDetails) {
    return null
  }

  return (
    <Tooltip
      interactive
      content={
        <div className="flex max-w-xs flex-col">
          {tooltipDetails?.CONTENT ? (
            <div>{tooltipDetails.CONTENT}</div>
          ) : (
            <div className="pb-2">For more information.</div>
          )}
          {tooltipDetails?.LINK && (
            <div className="mt-4 flex justify-end">
              <Button size="md" onClick={navigateTo}>
                Learn More
              </Button>
            </div>
          )}
        </div>
      }
    >
      <Button iconType="information" isOnlyIcon size="sm" variant="ghost" className={className} />
    </Tooltip>
  )
}
