import { useCallback } from 'react'

export function usePendoEvents() {
  const trackEvent = useCallback((eventKey, eventDetails) => {
    if (window.pendo?.track) {
      window.pendo.track(eventKey, eventDetails)
    }
  }, [])

  return {
    trackEvent,
  }
}
