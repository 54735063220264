/* eslint-disable */
import { IAuthError } from 'api/types'
import { useCallback } from 'react'

export function useRetryAsync(fn, maxRetries) {
  const retryAsync = useCallback(
    async (...args) => {
      let lastError: IAuthError | null = null

      for (let attempt = 0; attempt < maxRetries; attempt++) {
        try {
          return await fn(...args)
        } catch (error) {
          lastError = error as IAuthError
        }
      }

      throw lastError
    },
    [fn, maxRetries]
  )

  return retryAsync
}
