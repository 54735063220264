import queryString from 'query-string'
import isString from 'lodash/fp/isString'
import { DictionaryType } from 'types'

export function resolveQueryArray(queryValue) {
  if (Array.isArray(queryValue)) {
    return queryValue
  }
  return isString(queryValue) ? [queryValue] : []
}

export function parseQS(search: string) {
  return queryString.parse(search, { arrayFormat: 'comma' })
}

export function stringifyQS(newQueryObj: DictionaryType) {
  return queryString.stringify(newQueryObj, {
    arrayFormat: 'comma',
    skipNull: true,
    sort: false,
  })
}

export function mergeAndStringifyQS(search: string, newQueryObj: DictionaryType) {
  const currentQSObj = parseQS(search)
  return stringifyQS({ ...currentQSObj, ...newQueryObj })
}

export function buildQueryString(queryParams) {
  return queryString.stringify(queryParams, {
    skipEmptyString: true,
    skipNull: true,
  })
}
