import { useCallback, useMemo, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { parseISO, min, isEqual } from 'date-fns'
import { getStorageObject, setStorageObject } from 'utils/localStorageHelpers'
import { useCompany } from './useCompany'

interface IRecentItem {
  id: string
}

type IRecentItemReturn<T> = T & {
  lastViewed: string
  sourceCompanyId?: string
  sourceCompanyName?: string
}

interface ICurrentItems<T> {
  [key: string]: IRecentItemReturn<T>[]
}

interface IUseRecentItemsProps {
  maxItemCount: number
  storageKey: string
}

export function useRecentItems<T extends IRecentItem>({
  maxItemCount = 10,
  storageKey,
}: IUseRecentItemsProps) {
  const [version, setVersion] = useState(0)
  const { user } = useAuth0()
  const {
    company: { id: companyId },
    sourceCompany: { id: sourceCompanyId, name: sourceCompanyName } = {},
  } = useCompany()

  const key = `${user?.email}_${storageKey}`
  const subKey = sourceCompanyId ? `${companyId}-${sourceCompanyId}` : companyId

  // eslint disable is needed here to force useMemo on version change
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const currentItems: ICurrentItems<T> = useMemo(() => getStorageObject(key), [key, version])

  const items: IRecentItemReturn<T>[] = useMemo(
    () => (subKey && Object.hasOwn(currentItems, subKey) ? currentItems[subKey] : []),
    [currentItems, subKey]
  )

  const updateRecentItems = useCallback(
    (recentItem: T) => {
      if (!subKey) return

      const item: IRecentItemReturn<T> = {
        ...recentItem,
        lastViewed: new Date().toISOString(),
        sourceCompanyId,
        sourceCompanyName,
      }

      if (items.find(i => i.id === item.id)) {
        setStorageObject(key, {
          ...currentItems,
          [subKey]: items.map(i => (i.id === item.id ? item : i)),
        })
      } else if (items.length < maxItemCount) {
        setStorageObject(key, { ...currentItems, [subKey]: [...items, item] })
      } else {
        const lastEntryDate = min(items.map((i: any) => parseISO(i.lastViewed)))
        setStorageObject(key, {
          ...currentItems,
          [subKey]: [
            ...items.filter((i: any) => !isEqual(parseISO(i.lastViewed), lastEntryDate)),
            item,
          ],
        })
      }
      setVersion(v => v + 1)
    },
    [currentItems, items, maxItemCount, key, subKey, sourceCompanyId, sourceCompanyName]
  )

  return { items, updateRecentItems }
}
