import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { IChildrenProps } from '@mondra/ui-components/@types'
import { IGridFilterModel } from 'components/products-table/types'
import { IOption } from 'types'
import { ProductSystemEnum } from '../add-data-form/types'
import { IEFData, IEFProduct, Taxonomy } from './types'

interface IAssignProductsContext {
  clearData: () => void
  country?: string
  emissionFactors: IEFData[]
  productFilters: IGridFilterModel
  productSearchText: string
  productSystem: ProductSystemEnum
  selectedProducts: IEFProduct[]
  selectedSuppliers: IOption[]
  setCountry: (country?: string) => void
  setEmissionFactors: React.Dispatch<React.SetStateAction<IEFData[]>>
  setProductFilters: React.Dispatch<React.SetStateAction<IGridFilterModel>>
  setProductSearchText: React.Dispatch<React.SetStateAction<string>>
  setProductSystem: (system: ProductSystemEnum) => void
  setSelectedProducts: (selectedProducts: IEFProduct[]) => void
  setSelectedSuppliers: React.Dispatch<React.SetStateAction<IOption[]>>
  setSplitPercentage: React.Dispatch<React.SetStateAction<Record<string, string>>>
  splitPercentage: Record<string, string>
  setTaxonomy: (tx: Taxonomy) => void
  taxonomy?: Taxonomy
}

export const AssignProductsContext = createContext<IAssignProductsContext>({
  clearData: () => {},
  country: '',
  emissionFactors: [],
  productFilters: {},
  productSearchText: '',
  productSystem: ProductSystemEnum.CONVENTIONAL,
  selectedProducts: [],
  selectedSuppliers: [],
  setCountry: () => null,
  setEmissionFactors: () => null,
  setProductFilters: () => null,
  setProductSearchText: () => null,
  setProductSystem: () => null,
  setSelectedProducts: () => null,
  setSelectedSuppliers: () => [],
  setSplitPercentage: () => {},
  setTaxonomy: () => null,
  splitPercentage: {},
  taxonomy: undefined,
})

export function AssignProductsProvider({ children }: IChildrenProps) {
  const [country, setCountry] = useState<string>()
  const [productFilters, setProductFilters] = useState<IGridFilterModel>({})
  const [productSearchText, setProductSearchText] = useState<string>('')
  const [productSystem, setProductSystem] = useState<ProductSystemEnum>(
    ProductSystemEnum.CONVENTIONAL
  )
  const [taxonomy, setTaxonomy] = useState<Taxonomy>()
  const [emissionFactors, setEmissionFactors] = useState<IEFData[]>([])
  const [selectedProducts, setSelectedProducts] = useState<IEFProduct[]>([])
  const [selectedSuppliers, setSelectedSuppliers] = useState<IOption[]>([])
  const [splitPercentage, setSplitPercentage] = useState<Record<string, string>>({})

  useEffect(() => {
    setSplitPercentage(Object.fromEntries(emissionFactors.map(({ id }) => [id, ''])))
  }, [emissionFactors])

  const clearData = useCallback(() => {
    setEmissionFactors([])
    setProductFilters({})
    setProductSearchText('')
    setSelectedProducts([])
    setSelectedSuppliers([])
    setSplitPercentage({})
  }, [])

  const values = useMemo(
    () => ({
      clearData,
      country,
      emissionFactors,
      productFilters,
      productSearchText,
      productSystem,
      selectedProducts,
      selectedSuppliers,
      setCountry,
      setEmissionFactors,
      setProductFilters,
      setProductSearchText,
      setProductSystem,
      setSelectedProducts,
      setSelectedSuppliers,
      setSplitPercentage,
      setTaxonomy,
      splitPercentage,
      taxonomy,
    }),
    [
      clearData,
      country,
      emissionFactors,
      productFilters,
      productSearchText,
      productSystem,
      selectedProducts,
      selectedSuppliers,
      taxonomy,
      splitPercentage,
    ]
  )

  return <AssignProductsContext.Provider value={values}>{children}</AssignProductsContext.Provider>
}

export function useAssignProducts(): IAssignProductsContext {
  return useContext(AssignProductsContext)
}
