import React from 'react'
import { Button, Icon } from '@mondra/ui-components'
import classNames from 'classnames'

interface IDarkActionButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  isTransparent?: boolean
  iconType?: string
}

export const DarkActionButton = React.forwardRef<HTMLButtonElement, IDarkActionButtonProps>(
  function DarkActionButton({ children, iconType, isTransparent, ...rest }, ref) {
    return (
      <Button
        ref={ref}
        variant="ghost"
        size="sm"
        className={classNames(
          '!px-1.5 text-white focus:outline-none focus:!ring-0 active:outline-none active:!ring-0 motion-safe:transition-all',
          isTransparent ? '!bg-transparent' : '!bg-white/10 hover:!bg-white/20'
        )}
        {...rest}
      >
        {iconType ? <Icon type={iconType} size={20} className="stroke-2 text-current" /> : children}
      </Button>
    )
  }
)
