export const ROUTE_URLS = {
  ADD_DATA: '/add-data',
  ANALYSIS: '/analysis',
  BASE: '/',
  ENGAGEMENTS: '/suppliers',
  ENGAGEMENTS_INVITE: '/suppliers/invite/:stepPath',
  ERROR: '/error',
  HOME: '/home',
  NOT_FOUND: '*',
  OVERVIEW: '/overview',
  SCENARIO_DASHBOARD: '/scenarios/:scenarioId',
  SCENARIO_PRODUCT_DASHBOARD: '/scenarios/:scenarioId/:productId',
  SCENARIOS: '/scenarios',
  SETTINGS: '/settings',
  TARGETS: '/targets',
}

export const ANALYSIS_SUB_ROUTES = {
  CATEGORIES: 'categories',
  HOME: 'impacts',
  INGREDIENTS: 'ingredients',
  PRODUCTS: 'products',
  SCENARIOS: 'scenarios',
  SOURCING: 'sourcing',
  SUPPLIERS: 'suppliers',
  SUPPLY_CHAIN: 'supply-chain',
}

export const INVITE_WIZARD_PATHS = {
  STEP1: 'intro',
  STEP2: 'group-and-suppliers',
  STEP3: 'contact-details',
  STEP4: 'review-and-invite',
  STEP5: 'send-invite',
}

export const SETTINGS_SUB_ROUTES = {
  GENERAL: 'general',
}

export const ANALYSIS_BASE_PATH = `${ROUTE_URLS.ANALYSIS}/${ANALYSIS_SUB_ROUTES.HOME}`

export const ADD_DATA_SUB_ROUTES = {
  AG_DATA: 'ag-data',
  AG_DATA_WIZARD: `${ROUTE_URLS.ADD_DATA}/ag-data/:stepPath`,
  ASSIGN_PRODUCTS: 'assign-products',
  ASSIGN_PRODUCTS_WIZARD: `${ROUTE_URLS.ADD_DATA}/assign-products/:stepPath`,
}

export const AG_DATA_WIZARD_PATHS = {
  STEP1: 'add-ef-data',
  STEP2: 'review-publish',
  STEP3: 'assign-to-products',
}

export const ASSIGN_PRODUCTS_WIZARD_PATHS = {
  STEP1: 'select-efs',
  STEP2: 'select-suppliers',
  STEP3: 'select-products',
  STEP4: 'set-data-split',
}

export const ASSIGN_PRODUCTS_FIRST_STEP_PATH = `${ROUTE_URLS.ADD_DATA}/${ADD_DATA_SUB_ROUTES.ASSIGN_PRODUCTS}/${ASSIGN_PRODUCTS_WIZARD_PATHS.STEP1}`
