/* eslint-disable @typescript-eslint/no-explicit-any */
import { generatePath, Params } from 'react-router-dom'
import some from 'lodash/fp/every'
import isObject from 'lodash/fp/isObject'
import isArray from 'lodash/fp/isArray'
import { isNullify } from 'utils'
import { ROUTE_URLS } from 'constants/routeUrls'

type QueryParamType = string | string[][] | Record<string, any>

type NullifyParamType = QueryParamType | Params
export function checkIsNullify(params?: NullifyParamType) {
  if (isObject(params) && some(isNullify, Object.values(params))) {
    return true
  }
  if (isArray(params) && some((value: string[]) => isNullify(value[1]), params)) {
    return true
  }
  return false
}

export function resolveApiUrl(path: string, params?: Params, queryParams?: QueryParamType): string {
  const apiPath = `${process.env.REACT_APP_API_URL}${generatePath(path, params)}`
  if (queryParams && !checkIsNullify(queryParams)) {
    return `${apiPath}?${new URLSearchParams(queryParams).toString()}`
  }
  return apiPath
}

export function resolveSwrUrl(
  path: string,
  params?: Params,
  queryParams?: QueryParamType
): string | null {
  if (!path || checkIsNullify(params)) {
    return null
  }
  return resolveApiUrl(path, params, queryParams)
}

export function resolveUrl(path: string, params?: Params, queryParams?: QueryParamType): string {
  const url = generatePath(path, params)
  if (queryParams) {
    return `${url}?${new URLSearchParams(queryParams).toString()}`
  }
  return url
}

export function resolveRouteBaseUrl(urlPath: string, defaultBase: string = ROUTE_URLS.HOME) {
  const mainPath = urlPath?.split('/')[1]
  return mainPath ? `/${mainPath}` : defaultBase
}
