import { ImpactEnum } from '@mondra/ui-components'
import { Dimensions } from '.'

export const QS_ALIAS = {
  DASHBOARD: 'da',
  DIMENSION_IDS: 'did',
  DIMENSIONS: 'di',
  IMPACT_TYPE: 'it',
}

export enum DimensionsAlias {
  i = 'i',
  ic = 'ic',
  isc = 'isc',
  p = 'p',
  pc = 'pc',
  psc = 'psc',
  s = 's',
}

export const MapDimensionsEncode: Record<Dimensions, DimensionsAlias> = {
  [Dimensions.Ingredient]: DimensionsAlias.i,
  [Dimensions.IngredientCategory]: DimensionsAlias.ic,
  [Dimensions.IngredientSubCategory]: DimensionsAlias.isc,
  [Dimensions.Product]: DimensionsAlias.p,
  [Dimensions.ProductCategory]: DimensionsAlias.pc,
  [Dimensions.ProductSubCategory]: DimensionsAlias.psc,
  [Dimensions.Supplier]: DimensionsAlias.s,
}

export const MapDimensionsDecode: Record<DimensionsAlias, Dimensions> = {
  [DimensionsAlias.i]: Dimensions.Ingredient,
  [DimensionsAlias.ic]: Dimensions.IngredientCategory,
  [DimensionsAlias.isc]: Dimensions.IngredientSubCategory,
  [DimensionsAlias.p]: Dimensions.Product,
  [DimensionsAlias.pc]: Dimensions.ProductCategory,
  [DimensionsAlias.psc]: Dimensions.ProductSubCategory,
  [DimensionsAlias.s]: Dimensions.Supplier,
}

export enum ImpactTypeAlias {
  b = 'b',
  c = 'c',
  wp = 'wp',
  wu = 'wu',
}

export const MapImpactTypeEncode: Record<ImpactEnum, ImpactTypeAlias> = {
  [ImpactEnum.BIO]: ImpactTypeAlias.b,
  [ImpactEnum.CARBON]: ImpactTypeAlias.c,
  [ImpactEnum.WATER_POLLUTION]: ImpactTypeAlias.wp,
  [ImpactEnum.WATER_USAGE]: ImpactTypeAlias.wu,
}

export const MapImpactTypeDecode: Record<ImpactTypeAlias, ImpactEnum> = {
  [ImpactTypeAlias.b]: ImpactEnum.BIO,
  [ImpactTypeAlias.c]: ImpactEnum.CARBON,
  [ImpactTypeAlias.wp]: ImpactEnum.WATER_POLLUTION,
  [ImpactTypeAlias.wu]: ImpactEnum.WATER_USAGE,
}
